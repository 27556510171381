import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, Pagination, Grid, Typography, Button, Collapse, TextField } from '@mui/material';
import { ClusterObject, Flag, Installation, Outlier, PAPSCadranMonth, PAPSMonth, PipelineConfig } from '../types/type';
import { fetchClusters, fetchInstallation, fetchPA_PS_Cadran_Month_For_Installation, fetchPA_PS_Month_For_Installation, getFlags, get_naf } from '../api/api';
import { genericDataset } from '../types/type';
import InstallationDetailView from './InstallationDetailView';
import InstallationCharts from './InstallationCharts';

interface OutliersViewProps {
  outliers: Outlier[];
  pipeline: PipelineConfig
}

const ITEMS_PER_PAGE = 20;

const OutlierView: React.FC<OutliersViewProps> = ({ outliers,pipeline }) => {
  const [selectedOutlierId, setSelectedOutlierId] = useState<number>(-1);
  const [filteredOutliers, setFilteredOutliers] = useState<Outlier[]>([]);
  const [paginatedOutliers, setPaginatedOutliers] = useState<Outlier[]>([]);
  const [selectedOutlier, setSelectedOutlier] = useState<Outlier | null>(null);
  const [selectedPAPSCadranDatasets, setSelectedPAPSCadranDatasets] = useState<{ labels: string[], datasets: genericDataset[] }>({ labels: [], datasets: [] });
  const [selectedPAPS, setSelectedPAPS] = useState<PAPSMonth[]>([]);
  const [cluster, setYearCluster] = useState<ClusterObject | null>(null);
  const [ratioCluster, setRatioCluster] = useState<ClusterObject | null>(null);

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    console.log("selectedOutlierId", selectedOutlierId)
    if (isNaN(selectedOutlierId) || selectedOutlierId === -1) {
      return;
    }
    const fetchData = async () => {
      const outlier = outliers.find((outlier) => outlier.id === selectedOutlierId) || null;
      setSelectedOutlier(outlier);
    };

    fetchData();
  }, [selectedOutlierId, outliers]);

  useEffect(() => {
    if (!selectedOutlier) {
      return;
    }
    const fetchData = async () => {
      const cadran_data = await fetchPA_PS_Cadran_Month_For_Installation(selectedOutlier.Installation);
      const month_data = await fetchPA_PS_Month_For_Installation(selectedOutlier.Installation);
      const groupedData = cadran_data.reduce((acc, curr) => {
        if (!acc[curr.Numero_Cadran_PA]) {
          acc[curr.Numero_Cadran_PA] = [];
        }
        acc[curr.Numero_Cadran_PA].push(curr);
        return acc;
      }, {} as Record<number, PAPSCadranMonth[]>);

      const chartData = {
        labels: cadran_data.map((d) => d.month),
        datasets: Object.keys(groupedData).map((key) => ({
          label: `Cadran ${key}`,
          data: groupedData[Number(key)].map((d) => d.PA),
          borderColor: '#' + ((1 << 24) * Math.random() | 0).toString(16),
          fill: false,
        }))
      };
      setSelectedPAPSCadranDatasets(chartData);

      setSelectedPAPS(month_data);
      const foundYearClusters = await fetchClusters(selectedOutlier.model, "year", selectedOutlier?.cluster);
      const foundRatioClusters = await fetchClusters(selectedOutlier.model, "ratio", selectedOutlier?.ratio_cluster);
      if (foundYearClusters.length > 0) {
        setYearCluster(foundYearClusters[0]);
      }
      if (foundRatioClusters.length > 0) {
        setRatioCluster(foundRatioClusters[0]);
      }
    };

    fetchData();
  }, [selectedOutlier]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1); // Reset to first page when search query changes
  };

  useEffect(() => {
    const _filteredOutliers = outliers.filter(outlier =>
      outlier.Installation.toString().includes(searchQuery)
    );
    setFilteredOutliers(_filteredOutliers);
    setPaginatedOutliers(_filteredOutliers.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE));
  }, [searchQuery,outliers]);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel>Sélection Installation sur {outliers.length} </InputLabel>
        <Box px={2} py={1}>
          <TextField
            placeholder="Chercher..."
            value={searchQuery}
            onChange={handleSearchChange}
            fullWidth
            variant="standard"
          />
        </Box>
        <Select
          value={selectedOutlierId !== -1 ? selectedOutlierId : -1}
          onChange={(e) => setSelectedOutlierId(Number(e.target.value))}
        >
          <MenuItem value={-1}>Tous</MenuItem>
          {paginatedOutliers.map((outlier) => (
            <MenuItem key={outlier.id} value={outlier.id}>
              Installation {outlier.Installation} - Score: {outlier.score?.toFixed(2)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Pagination
        count={Math.ceil(filteredOutliers.length / ITEMS_PER_PAGE)}
        page={page}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: '20px' }}
      />

      {pipeline && selectedOutlier && (
        <><InstallationDetailView selectedOutlier={selectedOutlier} />
        <InstallationCharts
          pipeline={pipeline}
          selectedPAPS={selectedPAPS}
          selectedPAPSCadranDatasets={selectedPAPSCadranDatasets}
          cluster={cluster}
          ratioCluster={ratioCluster} /></>
      )}

      
    </Box>
  );
};

export default OutlierView;
