// indexedDBCache.ts

const cacheName = "db_anomalies"
const objectStoreName = "data"
export class IndexedDBCache {
    private static dbPromise: Promise<IDBDatabase>;
  
    static init() {
      if (!this.dbPromise) {
        this.dbPromise = new Promise((resolve, reject) => {
          const request = indexedDB.open(cacheName, 1);
          request.onupgradeneeded = () => {
            const db = request.result;
            if (!db.objectStoreNames.contains(objectStoreName)) {
              db.createObjectStore(objectStoreName, { keyPath: 'key' });
            }
          };
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });
      }
      return this.dbPromise;
    }
  
    static async get(key: string): Promise<any> {
      const db = await this.init();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(objectStoreName, 'readonly');
        const store = transaction.objectStore(objectStoreName);
        const request = store.get(key);
        request.onsuccess = () => {
          if (request.result) {
            const { value, expiry } = request.result;
            if (expiry && expiry < Date.now()) {
              this.remove(key);
              resolve(null);
            } else {
              resolve(value);
            }
          } else {
            resolve(null);
          }
        };
        request.onerror = () => reject(request.error);
      });
    }
  
    static async set(key: string, value: any, ttl: number = 3600 * 1000): Promise<void> {
      const db = await this.init();
      const expiry = Date.now() + ttl;
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(objectStoreName, 'readwrite');
        const store = transaction.objectStore(objectStoreName);
        const request = store.put({ key, value, expiry });
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    }
  
    static async remove(key: string): Promise<void> {
      const db = await this.init();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(objectStoreName, 'readwrite');
        const store = transaction.objectStore(objectStoreName);
        const request = store.delete(key);
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    }
  
    static async clear(): Promise<void> {
      const db = await this.init();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(objectStoreName, 'readwrite');
        const store = transaction.objectStore(objectStoreName);
        const request = store.clear();
        request.onsuccess = () => resolve();
        request.onerror = () => reject(request.error);
      });
    }
  }
  