import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Flag } from '../types/type';

interface FlagItemProps {
  flag: Flag;
  editingFlag: number | null;
  startEditing: (flag: Flag) => void;
  cancelEditing: () => void;
  handleUpdateFlag: (flagId: number, comment: string, date: string) => void;
  handleDeleteFlag: (flagId: number) => void;
  months: string[];
}

const FlagItem: React.FC<FlagItemProps> = ({
  flag,
  editingFlag,
  startEditing,
  cancelEditing,
  handleUpdateFlag,
  handleDeleteFlag,
  months
}) => {
  const [editingComment, setEditingComment] = useState(flag.flag_comment);
  const [editingDate, setEditingDate] = useState(flag.flagged_date);

  return (
    <ListItem key={flag.id}>
      <ListItemText
        primary={editingFlag === flag.id ? (
          <>
            <TextField
              label="Edit Comment"
              value={editingComment}
              onChange={(e) => setEditingComment(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Select
              value={editingDate}
              onChange={(e) => setEditingDate(e.target.value as string)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                Select Date
              </MenuItem>
              {months.map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : (
          <>
            <Typography variant="body1">Comment: {flag.flag_comment}</Typography>
            <Typography variant="body2">Date: {flag.flagged_date}</Typography>
          </>
        )}
      />
      <ListItemSecondaryAction>
        {editingFlag === flag.id ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleUpdateFlag(flag.id, editingComment, editingDate)}
            >
              Save
            </Button>
            <Button onClick={cancelEditing}>Cancel</Button>
          </>
        ) : (
          <>
            <IconButton edge="end" aria-label="edit" onClick={() => startEditing(flag)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFlag(flag.id)}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default FlagItem;
