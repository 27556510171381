import { Outlier } from "../types/type";

export interface filterInfo{
  title:string,
  value:number,
  min:number,
  max:number,
  step:number
  column:string,
  inferior:boolean
  defaultValue?:number
}

type NumericKeys<T> = {
    [K in keyof T]: T[K] extends number ? K : never;
  }[keyof T];
  
  export const getColumnNumericValue = <T, K extends NumericKeys<T>>(items: T[], column: K, isMin: boolean): number => {
    if (isMin) {
      return items.reduce((min, item) => {
        const value = item[column] as unknown as number;
        return value < min ? value : min;
      }, Infinity);
    } else {
      return items.reduce((max, item) => {
        const value = item[column] as unknown as number;
        return value > max ? value : max;
      }, -Infinity);
    }
  };

  export const getMinMaxColumnNumericValue = <T, K extends NumericKeys<T>>(items: T[], column: K): number[] => {
    const min = items.reduce((min, item) => {
        const value = item[column] as unknown as number;
        return value < min ? value : min;
      }, Infinity);
    const max= items.reduce((max, item) => {
        const value = item[column] as unknown as number;
        return value > max ? value : max;
      }, -Infinity);
      return [min,max] 
    }
  
  export const filterOutliers = (outliers: Outlier[], filters: filterInfo[]): Outlier[] => {
      return outliers.filter(outlier => {
        return filters.every(filter => {
          const value = outlier[filter.column as keyof Outlier] as unknown as number;
          if (filter.inferior) {
            return value <= filter.value;
          } else {
            return value >= filter.value;
          }
        });
      });
    };