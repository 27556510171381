import { Outlier } from '../types/type';
import { filterInfo, getColumnNumericValue } from '../utils/getValues';


export const get_oulierFilters=(data:Outlier[]):filterInfo[]=>{
    const filter1:filterInfo={
        title:'Ecart par rapport au cluster (SignedMaxDistance), en dessous de',
        value:0.,
        column:'SignedMaxDistance',
        min:getColumnNumericValue(data,'SignedMaxDistance',true),
        max:getColumnNumericValue(data,'SignedMaxDistance',false),
        step:0.01,
        inferior:true,
        defaultValue:0.01
      }
    
      const filter2:filterInfo={
        title:'Ecart par rapport au ratio cluster (SignedMaxDistance), en dessous de:',
        value:0.,
        column:'ratio_SignedMaxDistance',
        min:getColumnNumericValue(data,'ratio_SignedMaxDistance',true),
        max:getColumnNumericValue(data,'ratio_SignedMaxDistance',false),
        step:0.01,
        inferior:true,
        defaultValue:0.01
      }
    
      const filter3:filterInfo={
        title:'Ecart entre année (dtw), au dessus de:',
        value:0.,
        column:'DTW_Distance',
        min:getColumnNumericValue(data,'DTW_Distance',true),
        max:getColumnNumericValue(data,'DTW_Distance',false),
        step:0.01,
        inferior:false,
        defaultValue:0.01
      }
    
      const filter4:filterInfo={
        title:'Différence N/N-1, en dessous de:',
        value:0.,
        column:'DIFF_AVERAGE',
        min:getColumnNumericValue(data,'DIFF_AVERAGE',true),
        max:getColumnNumericValue(data,'DIFF_AVERAGE',false),
        step:0.01,
        inferior:true,
        defaultValue:1.
      }
    
      const filter5:filterInfo={
        title:'Ecart significatif à partir de:',
        value:0.,
        column:'SIGNIFICANT_CHANGE_INDEX',
        min:getColumnNumericValue(data,'SIGNIFICANT_CHANGE_INDEX',true),
        max:getColumnNumericValue(data,'SIGNIFICANT_CHANGE_INDEX',false),
        step:1,
        inferior:false
      }
    
      const filter6:filterInfo={
        title:'Ecart significatif en dessous de :',
        value:0.,
        column:'SIGNIFICANT_CHANGE_RATIO',
        min:getColumnNumericValue(data,'SIGNIFICANT_CHANGE_RATIO',true),
        max:getColumnNumericValue(data,'SIGNIFICANT_CHANGE_RATIO',false),
        step:0.01,
        inferior:true,
        defaultValue:1.
      }

      const filters=[filter1,filter2,filter3,filter4,filter5,filter6]
      filters.forEach(f=>[
        f.value = f.defaultValue? f.defaultValue : f.inferior ? f.max : f.min
      ])
      return filters
}

