import React, { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Box, Checkbox, FormControlLabel } from '@mui/material';
import { fetchClusters } from '../api/api';
import { ClusterObject } from '../types/type';
import ClusterChart from './ClusterChart'; // Assume you have a Chart component already implemented

interface ClusterProps {
  modelName: string;
  clusterType: string;
  selectedCluster: ClusterObject | null;
  setSelectedCluster: (model: ClusterObject | null) => void;
  setCluster: (model: ClusterObject | null) => void;
}

const ClusterView: React.FC<ClusterProps> = ({ modelName, clusterType,selectedCluster,setCluster: setSelectedCluster }) => {
  const [clusters, setClusters] = useState<ClusterObject[]>([]);
  const [clusterId, setClusterId] = useState<number>(-1);
  const [values, setValues] = useState<number[]>([]);
  const [sortByOutlierCount, setSortByOutlierCount] = useState<boolean>(false);

  useEffect(() => {
    const getClusters = async () => {
      const data = await fetchClusters(modelName, clusterType);
      setClusters(data);
    };

    getClusters();
  }, [modelName, clusterType]);

  useEffect(() => {
    if (selectedCluster !== null) {
      const cluster = clusters.find(c => c.cluster === selectedCluster.cluster);
      if (cluster) {
        setValues(cluster.values);
      }
    }
  }, [selectedCluster, clusters]);

  const handleSortChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSortByOutlierCount(event.target.checked);
  };

  const handleClusterSelection=(changeEvent: any)=> {
    const clusterId = changeEvent.target.value;
    setClusterId(clusterId);
    const cluster = clusters.find(c => c.cluster === Number(clusterId));
    setSelectedCluster(cluster || null as unknown as ClusterObject);
  }

  const sortedClusters = [...clusters].sort((a, b) => {
    if (sortByOutlierCount) {
      return b.outlier_count - a.outlier_count;
    } else {
      return a.cluster - b.cluster;
    }
  });

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={sortByOutlierCount}
            onChange={handleSortChange}
            name="sortByOutlierCount"
            color="primary"
          />
        }
        label="Trier par nb Outliers"
      />
      <FormControl fullWidth>
        <InputLabel>Sélection Cluster {clusterType}</InputLabel>
        <Select
          value={clusterId}
          onChange={handleClusterSelection}
        >
           <MenuItem  value={-1}>
              Tous
            </MenuItem>
          {sortedClusters.map((cluster) => (
            <MenuItem key={cluster.cluster} value={cluster.cluster}>
              {cluster.cluster} _ {cluster.outlier_count}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedCluster !== null && (
        <ClusterChart data={values} model={modelName} clusterType={clusterType} />
      )}
    </Box>
  );
};

export default ClusterView;
