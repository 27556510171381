import React, { useState } from 'react';
import Dropdown from './Dropdown';
import { PipelineConfig } from '../types/type';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { scoreFunctions } from '../utils/scores';
import { fetchPipelineConfigs, getDefaultUrl } from '../api/api';

interface SidebarProps {
  pipelines: PipelineConfig[];
  selectedPipeline: PipelineConfig | null;
  setPipeline: (model: PipelineConfig | null) => void;
  scoreFunc: string;
  setScoreFunc: (scoreFunction: string) => void;
  baseUrl:string;
  setBaseUrl:(url:string)=>void
}

const Sidebar: React.FC<SidebarProps> = ({ pipelines, selectedPipeline, setPipeline: setModel, scoreFunc, setScoreFunc,baseUrl,setBaseUrl }) => {
  const [userBaseURL, setUserBaseURL] = useState(getDefaultUrl());

  const handleSetBaseURL = () => {
    setBaseUrl(userBaseURL);
  };

  return (
    <aside style={{ width: '200px', padding: '10px', backgroundColor: '#f0f0f0' }}>
      { pipelines.length === 0 && 
      <Box>
      <Typography variant="h6" gutterBottom>
        Serveur Base URL
      </Typography>
      <TextField
        label="Base URL"
        variant="outlined"
        value={userBaseURL}
        onChange={(e) => setUserBaseURL(e.target.value)}
        placeholder="Entrez Base URL du serveur"
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleSetBaseURL} fullWidth>
        Sauver Base URL
      </Button>
      </Box>}
      { (pipelines.length>0) &&
      <><Dropdown
          label="Sélectionner un modèle"
          options={pipelines}
          value={selectedPipeline}
          onChange={setModel}
          getOptionLabel={(model) => model ? model.pipeline_name : ''} /><br /><hr /><br />
          <FormControl fullWidth>
            <InputLabel>Fonction Score</InputLabel>
            <Select
              value={scoreFunc}
              onChange={(e) => setScoreFunc(e.target.value as string)}
            >
              {Object.keys(scoreFunctions).map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            </Select>
          </FormControl></>
      }
    </aside>
  );
};

export default Sidebar;
