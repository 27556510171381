import React, { useState, useEffect } from 'react';

import { ClusterObject } from '../types/type';
import ClusterView from './ClusterView';
import { Box, Button, Collapse } from '@mui/material';

interface ClusterProps {
    modelName: string;
    selectedYearCluster: ClusterObject | null;
    setSelectedYearCluster: (model: ClusterObject | null) => void;
    selectedRatioCluster: ClusterObject | null;
    setSelectedRatioCluster: (model: ClusterObject | null) => void;
    handleImportClick: () => {}
}

const ClustersView: React.FC<ClusterProps> = ({ modelName, selectedYearCluster, setSelectedYearCluster, selectedRatioCluster, setSelectedRatioCluster, handleImportClick }) => {
    const [clusterBlockVisible, setClusterBlockVisible] = useState<boolean>(true);
    return (
        <Box component="section" sx={{ p: 2, border: '1px dashed grey', marginTop: '10px' }}>
            <Button variant="outlined" color="secondary" onClick={() => setClusterBlockVisible(!clusterBlockVisible)}>
                {clusterBlockVisible ? 'Cacher Clusters' : 'Afficher Clusters'}
            </Button>
            <Collapse in={clusterBlockVisible}>
                <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>

                    <div style={{ flex: 1 }}>
                        <ClusterView modelName={modelName} clusterType="year" selectedCluster={selectedYearCluster} setCluster={setSelectedYearCluster}  setSelectedCluster={setSelectedYearCluster}/>
                    </div>
                    <div style={{ flex: 1 }}>
                        <ClusterView modelName={modelName} clusterType="ratio" selectedCluster={selectedRatioCluster} setCluster={setSelectedRatioCluster} setSelectedCluster={setSelectedRatioCluster}/>
                    </div>
                    <Button variant="contained" color="primary" onClick={handleImportClick}>Importer</Button>

                </div>
            </Collapse>
        </Box>
    );
};

export default ClustersView;
