import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import { Installation, Outlier } from '../types/type';
import { getCity } from '../data/region_data';
import FlagList from './FlagList';
import { fetchInstallation, get_naf } from '../api/api';

interface InstallationDetailViewProps {
  selectedOutlier: Outlier | null;
}

const InstallationDetailView: React.FC<InstallationDetailViewProps> = ({ selectedOutlier }) => {
  const [filterDetailVisible, setDetailBlockVisible] = useState<boolean>(false);
  const [installationDetail, setInstallationDetail] = useState<Installation | null>(null);
  const displayedAttributes = [
    'MaxDistColumn',
    'AverageDistance',
    'ratio_MaxDistColumn',
    'ratio_AverageDistance',
    'DTW_Distance',
    'DIFF_AVERAGE',
    'SIGNIFICANT_CHANGE_INDEX',
    'SignedMaxDistance',
    'ratio_SignedMaxDistance',
    'SIGNIFICANT_CHANGE_RATIO',
    'score'
  ];

  useEffect(() => {
    if (!selectedOutlier) {
      setInstallationDetail(null);
      return;
    }

    const fetchData = async () => {
      const details = await fetchInstallation(selectedOutlier.Installation);
      details.libelle_NAF = await get_naf(details.Code_NAF);
      setInstallationDetail(details);
    }

    fetchData();
  }, [selectedOutlier]);

  if (!selectedOutlier) {
    return <Typography variant="h6">Select Installation</Typography>;
  }

  return (
    <>
      {installationDetail && (
        <>
          <Typography variant="h6" gutterBottom>
            Installation: {installationDetail.Installation} -- Ville: {getCity(installationDetail.REGION)} -- CodeNaf {installationDetail.Code_NAF} ({installationDetail.libelle_NAF})
          </Typography>
          <Button variant="outlined" color="secondary" onClick={() => setDetailBlockVisible(!filterDetailVisible)}>
            {filterDetailVisible ? 'Cacher Détail' : 'Afficher Détail'}
          </Button>
          <Collapse in={filterDetailVisible}>
            <Box component="section" sx={{ p: 2, border: '1px dashed grey', marginTop: '10px' }}>
            <Grid container spacing={2}>
              {displayedAttributes.map((attribute) => (
                <Grid item xs={3} key={attribute}>
                  <Typography variant="body2">
                    <strong>{attribute}:</strong> {selectedOutlier[attribute as keyof Outlier]}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            </Box>
            <FlagList installationId={installationDetail.Installation} />
          </Collapse>
        </>
      )}
    </>
  );
};

export default InstallationDetailView;
