import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Grid, Typography } from '@mui/material';
import { ClusterObject, Installation, Outlier, PAPSMonth, PipelineConfig, genericDataset } from '../types/type';
import { fetchInstallation, get_naf, getDTWAlignment } from '../api/api';
import GenericChart from './GenericChart';
import { getAllMonth, getDiFFPADivMaxPA, getMonthsForYear2023, getPAPSValuesForYear, getPAValuesForYear, getPAdivPSDividedForYear, getPSValuesForYear } from '../utils/filter';


interface InstallationChartsProps {
  selectedPAPSCadranDatasets: { labels: string[], datasets: genericDataset[] }
  selectedPAPS: PAPSMonth[];
  cluster: ClusterObject | null
  ratioCluster: ClusterObject | null
  pipeline: PipelineConfig
}


const InstallationCharts: React.FC<InstallationChartsProps> = ({ pipeline, selectedPAPSCadranDatasets, selectedPAPS, cluster, ratioCluster }) => {
  const [alignedDtw2023, setAlignedDtw2023] = useState<number[]>([]);

  useEffect(() => {
    if (selectedPAPS.length ===0) {
      return
    }
    const fetchData = async () => {
      const response = await getDTWAlignment({values_1:getPAPSValuesForYear(selectedPAPS, 2022, pipeline.base_column),values_2:getPAPSValuesForYear(selectedPAPS, 2023, pipeline.base_column),radius:2});
        setAlignedDtw2023(response.realigned_array_2);
    };

    fetchData();
  }
, [selectedPAPS]);
  


  return (
    <>
      {selectedPAPS && (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={6} >
            {selectedPAPS.length > 0 && (
              <GenericChart
                labels={getMonthsForYear2023(selectedPAPS)}
                title={'PA PS 2023'}
                datasets={[
                  {
                    data: getPAValuesForYear(selectedPAPS, 2023),
                    label: 'PA',
                    borderColor: 'blue',
                    fill: false
                  },
                  {
                    data: getPSValuesForYear(selectedPAPS, 2023),
                    label: 'PS',
                    borderColor: 'red',
                    fill: false
                  }
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {cluster && cluster.values && (
              <GenericChart
                labels={getMonthsForYear2023(selectedPAPS)}
                title={'Cluster n°' + cluster.cluster +' pour 2023 sur ' + pipeline.base_column }
                datasets={[
                  {
                    data: cluster.values,
                    label: 'cluster',
                    borderColor: 'gray',
                    fill: false
                  },
                  {
                    data: getPAPSValuesForYear(selectedPAPS, 2023, pipeline.base_column),
                    label: pipeline.base_column,
                    borderColor: 'blue',
                    fill: false
                  }
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {selectedPAPS.length > 0 && (
              <GenericChart
                labels={getMonthsForYear2023(selectedPAPS)}
                title={'PA 2022, 2023'}
                datasets={[
                  {
                    data: getPAValuesForYear(selectedPAPS, 2023),
                    label: 'PA 2023',
                    borderColor: 'blue',
                    fill: false
                  },
                  {
                    data: getPAValuesForYear(selectedPAPS, 2022),
                    label: 'PA 2022',
                    borderColor: 'red',
                    fill: false
                  }
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {ratioCluster && ratioCluster.values && (
              <GenericChart
                labels={getMonthsForYear2023(selectedPAPS)}
                title={'Cluster n°'+ratioCluster.cluster+ ' évolution 2023/2022'}
                datasets={[
                  {
                    data: ratioCluster.values,
                    label: 'cluster',
                    borderColor: 'gray',
                    fill: false
                  },
                  {
                    data: getDiFFPADivMaxPA(selectedPAPS, 2023, 2022),
                    label: '(PA2023-PA2022)/maxPA2023',
                    borderColor: 'green',
                    fill: false
                  }
                ]}
                minY={Math.min(...ratioCluster.values, ...getDiFFPADivMaxPA(selectedPAPS, 2023, 2022))}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {alignedDtw2023.length > 0 && (
              <GenericChart
                labels={getMonthsForYear2023(selectedPAPS)}
                title={'2023 vs 2022 pour ' + pipeline.base_column}
                datasets={[
                  {
                    data: getPAPSValuesForYear(selectedPAPS, 2023, pipeline.base_column),
                    label: '2023',
                    borderColor: 'blue',
                    fill: false
                  },
                  {
                    data: getPAPSValuesForYear(selectedPAPS, 2022, pipeline.base_column),
                    label: '2022',
                    borderColor: 'red',
                    fill: false
                  },
                  {
                    data: alignedDtw2023,
                    label: 'aligned 2023',
                    borderColor: 'black',
                    fill: false
                  },
                ]}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            {selectedPAPSCadranDatasets.labels.length > 0 && (
              <GenericChart
                labels={getAllMonth(selectedPAPS)}
                title={'PA par Cadran'}
                datasets={selectedPAPSCadranDatasets.datasets}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default InstallationCharts;
