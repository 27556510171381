const csvData = `
Code,Ville,DR
11,Lille metropole,DR NORD-PAS-DE-CALAIS
12,Hainaut-Cambresis,DR NORD-PAS-DE-CALAIS
13,Douai,DR NORD-PAS-DE-CALAIS
14,Arras,DR NORD-PAS-DE-CALAIS
15,Cote d'Opale,DR NORD-PAS-DE-CALAIS
01A,Pays de Somme - sans l'Oise,DR PICARDIE
01B,Pays de Somme - Oise,DR PICARDIE
21,Normandie Rouen,DR NORMANDIE
22,Normandie Eure,DR NORMANDIE
23,Le Havre Porte Oceane,DR NORMANDIE
24,Calvados,DR NORMANDIE
25,Manche,DR NORMANDIE
26,Orne,DR NORMANDIE
41,Pays de l'Aisne,DR PAYS DE LA LOIRE
42,Ardennes,DR CHAMPAGNE-ARDENNE
43,Reims-Champagne,DR CHAMPAGNE-ARDENNE
45,Champagne Sud,DR CHAMPAGNE-ARDENNE
51,Nancy Lorraine,DR LORRAINE
52,Haute-Marne,DR CHAMPAGNE-ARDENNE
54,Vosges,DR LORRAINE
55,Metz Lorraine,DR LORRAINE
56,Lorraine trois frontieres,DR LORRAINE
57,Meuse,DR LORRAINE
63,Alsace,DR ALSACE FRANCHE-COMTÉ
64,Franche-Comte Nord,DR ALSACE FRANCHE-COMTÉ
65,Franche-Comte Sud,DR ALSACE FRANCHE-COMTÉ
07A,Paris 1,DR PARIS
07B,Paris 2,DR PARIS
07C,Paris 3,DR PARIS
07D,Paris 4,DR PARIS
07E,Paris 5,DR PARIS
07F,Paris 6,DR PARIS
07G,Paris 7,DR PARIS
07H,Paris 8,DR PARIS
07I,Paris 9,DR PARIS
07J,Paris 10,DR PARIS
07K,Paris 11,DR PARIS
07L,Paris 12,DR PARIS
07M,Paris 13,DR PARIS
07N,Paris 14,DR PARIS
07O,Paris 15,DR PARIS
07P,Paris 16,DR PARIS
07Q,Paris 17,DR PARIS
07R,Paris 18,DR PARIS
07S,Paris 19,DR PARIS
07T,Paris 20,DR PARIS
91,Sarthe,DR PAYS DE LA LOIRE
92,La Mayenne,DR PAYS DE LA LOIRE
93,Anjou,DR PAYS DE LA LOIRE
94,Chartres Eure-et-Loir,DR CENTRE - VAL DE LOIRE
95,Loiret,DR CENTRE - VAL DE LOIRE
96,Loir-et-Cher,DR CENTRE - VAL DE LOIRE
97,Touraine,DR CENTRE - VAL DE LOIRE
121,Bourgogne du sud,DR BOURGOGNE
122,Cote d'Or,DR BOURGOGNE
124,Yonne,DR BOURGOGNE
125,Nievre,DR BOURGOGNE
142,Nantes Atlantique,DR PAYS DE LA LOIRE
143,Vendee,DR PAYS DE LA LOIRE
144,Ille-et-Vilaine,DR BRETAGNE
145,Cotes d'Armor,DR BRETAGNE
146,Iroise,DR BRETAGNE
147,Cornouaille,DR BRETAGNE
148,Morbihan,DR BRETAGNE
151,Val de Charente,DR POITOU-CHARENTES
152,Charente-maritime,DR POITOU-CHARENTES
154,Vienne et Sevres,DR POITOU-CHARENTES
155,Haute-Vienne Creuse,DR LIMOUSIN
161,Gironde,DR AQUITAINE NORD
162,Perigord,DR AQUITAINE NORD
163,Lot et Garonne,DR AQUITAINE NORD
164,Sud-Aquitaine,DR AQUITAINE NORD
165,Bearn Bigorre,DR PYRÉNÉES ET LANDES
171,Clermont-Ferrand,DR AUVERGNE
172,Montlucon,DR AUVERGNE
175,Correze,DR LIMOUSIN
176,Indre en Berry,DR CENTRE - VAL DE LOIRE
177,Cher en Berry,DR CENTRE - VAL DE LOIRE
179,Grand Velay,DR AUVERGNE
17A,Bourbonnais,DR AUVERGNE
17B,Bourbonnais - St Rirand,DR AUVERGNE
180,Cantal,DR AUVERGNE
191,Lyon metropole,DR SILLON RHODANIEN
193,Alpes Dauphine,DR PROVENCE-ALPES DU SUD
194,Vienne Pays de Rhone,DR SILLON RHODANIEN
195,Annecy Leman,DR ALPES
196,Savoie,DR ALPES
197,Drome-Ardeche,DR SILLON RHODANIEN
198,Loire,DR SILLON RHODANIEN
199,Pays de l'Ain-Beaujolais,DR SILLON RHODANIEN
211,Versailles,DR ÎLE-DE-FRANCE OUEST
212,Cergy,DR ÎLE-DE-FRANCE OUEST
213,Bagneux,DR ÎLE-DE-FRANCE OUEST
21A,Asnieres - Saint Ouen L'aumône,DR ÎLE-DE-FRANCE OUEST
21B,Asnieres - Puteaux,DR ÎLE-DE-FRANCE OUEST
21C,Nanterre - Saint Ouen L'aumône,DR ÎLE-DE-FRANCE OUEST
21D,Nanterre - Puteaux,DR ÎLE-DE-FRANCE OUEST
223,Villejuif,DR ÎLE-DE-FRANCE EST
225,Pantin,DR ÎLE-DE-FRANCE EST
22A,Saint-Mande - Villejuif GCPT,DR ÎLE-DE-FRANCE EST
22B,Saint-Mande - Noisy,DR ÎLE-DE-FRANCE EST
22C,Seine et Marne - Melun,DR ÎLE-DE-FRANCE EST
22D,Seine et Marne - Noisy,DR ÎLE-DE-FRANCE EST
22E,Essonne - Villejuif GCPT,DR ÎLE-DE-FRANCE EST
22F,Essonne - Melun,DR ÎLE-DE-FRANCE EST
231,Grand Toulouse,DR MIDI-PYRÉNÉES SUD
232,Garonne et Tarn,DR NORD MIDI-PYRÉNÉES
233,Pyrenees Gascogne,DR PYRÉNÉES ET LANDES
234,Lot,DR AQUITAINE NORD
235,Aveyron Lozere,DR NORD MIDI-PYRÉNÉES
241,Vallees d'Aude,DR LANGUEDOC-ROUSSILLON
242,Pyrenees Roussillon,DR LANGUEDOC-ROUSSILLON
243,Montpellier Herault,DR LANGUEDOC-ROUSSILLON
245,Gard-Cevennes,DR LANGUEDOC-ROUSSILLON
251,Marseille,DR PROVENCE-ALPES DU SUD
252,Provence,DR PROVENCE-ALPES DU SUD
253,Var,DR CÔTE D’AZUR
254,Nice Alpes Cote D'azur,DR CÔTE D’AZUR
256,Alpes du Sud,DR PROVENCE-ALPES DU SUD
258,Avignon Grand Delta,DR PROVENCE-ALPES DU SUD
259,Cannes,DR CÔTE D’AZUR
999,Localité non gérée par ENEDIS,
GSRF1,F - Région IDF,
GSRF2,F - Région Manche Mer du Nord,
GSRF3,F - Région Est,
GSRF4,F - Région Rhône Alpes Bourgogne,
GSRF5,F - Région Méditerranée,
GSRF6,F - Région Sud Ouest,
GSRF7,F - Région Ouest,
GSRF8,F - Région Centre,
GSRRE,GSR Fictif Responsable d'équilibre,
XXX,GSR par défaut pour BPEM,
`;

const lines = csvData.trim().split('\n');
const headers = lines[0].split(',');

interface region{
  Ville?: string,
  DR?: string
}

const codes:{ [Code: string] : region; } = {}

lines.slice(1).forEach(line => {
  const values = line.split(',');
  const obj:region = {};
  codes[values[0]]={
    Ville:values[1],
    DR:values[2]
  }
});

//@ts-ignore
export const getCity = (Code:string)=> { 
  if (typeof codes[Code] === "undefined") {
    return Code
  }
  const foundRow = codes[Code]
  return foundRow.Ville
}


