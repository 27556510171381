import axios from 'axios';
import { PAPSCadranMonth, Installation, PipelineConfig, Outlier, ClusterObject, PAPSMonth, Flag } from '../types/type';
import { IndexedDBCache } from './indexedDBCache';
import { DTWRequestBody, DTWResponseBody } from '../types/dtw_interfaces';

let baseURL = ''//'http://192.168.1.27:8001/v1/';

export const setAPIBaseUrl=(url:string)=>{
  console.log("setBaseUrl "+url)
  baseURL = url;
  localStorage.setItem("baseUrl",baseURL)
}

export const getDefaultUrl=():string=>
  localStorage.getItem("baseUrl") || "";

axios.interceptors.request.use((config) => {
  config.headers['ngrok-skip-browser-warning'] = 'True';
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const fetchPipelineConfigs = async (): Promise<PipelineConfig[]> => {
  try {
    
    const response = await axios.get<PipelineConfig[]>(baseURL+'models');
    return response.data;
  } catch (error) {
    console.error('Error fetching pipelines:', error);
    return [];
  }
};

export const fetchClusters = async (model:string,type:string="", cluster:number=-1): Promise<ClusterObject[]> => {
  try {
    console.log("fetchClusters "+model+"/"+type)
    const cacheKey = `${baseURL}_clusters_${model}_${cluster}_${type}`;
    const cachedData = await IndexedDBCache.get(cacheKey);
    console.log("cacheKey="+cacheKey)
    if (cachedData) {
      console.log("returning cache")
      return cachedData;

    }
    const response = await axios.post<ClusterObject[]>(`${baseURL}clusters`, {
      model,
      cluster,
      type,
    });
    console.log("setting cache")
    await IndexedDBCache.set(cacheKey, response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching Clusters:', error);
    return [];
  }
};

export const fetchPA_PS_Cadran_Month_For_Installation = async (installation:number): Promise<PAPSCadranMonth[]> => {
  try {
    const response = await axios.get<PAPSCadranMonth[]>(baseURL+'pa_ps/'+installation);
    return response.data;
  } catch (error) {
    console.error('Error fetching PA_PS_Cadan_Months:', error);
    return [];
  }
};

export const fetchPA_PS_Month_For_Installation = async (installation:number): Promise<PAPSMonth[]> => {
  try {
    const response = await axios.get<PAPSMonth[]>(baseURL+'installation_month/'+installation);
    return response.data;
  } catch (error) {
    console.error('Error fetching PA_PS_Months:', error);
    return [];
  }
};

export const fetchPA_PS_Months = async (): Promise<PAPSCadranMonth[]> => {
  try {
    const response = await axios.get<PAPSCadranMonth[]>(baseURL+'pa_ps');
    return response.data;
  } catch (error) {
    console.error('Error fetching PA_PS_Months:', error);
    return [];
  }
};

export const fetchInstallation = async (installationId:number): Promise<Installation> => {
  try {
    const response = await axios.get<Installation>(`${baseURL}installations/${installationId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching Installation:', error);
    return null as unknown as Installation;
  }
};

export const fetchInstallations = async (): Promise<Installation[]> => {
  try {
    const response = await axios.get<Installation[]>(baseURL+'installations');
    return response.data;
  } catch (error) {
    console.error('Error fetching Installations:', error);
    return [];
  }
};

export const fetchOutliers = async (model: string, cluster: number, ratio_cluster: number, installation: number): Promise<Outlier[]> => {
  console.log("fetchOutliers "+model+"/"+cluster+"/"+ratio_cluster+"/"+installation)
  const cacheKey = `${baseURL}_outliers_${model}_${cluster}_${ratio_cluster}_${installation}`;
  const cachedData = await IndexedDBCache.get(cacheKey);

  if (cachedData) {
    return cachedData;
  }

  try {
    const response = await axios.post<Outlier[]>(`${baseURL}outliers`, {
      model,
      cluster,
      ratio_cluster,
      installation,
    });
    const data = response.data;
    IndexedDBCache.set(cacheKey, data);
    return data;
  } catch (error) {
    console.error('Error fetching Outliers:', error);
    return [];
  }
};


export const getAllFlags = async (): Promise<Flag[]> => {
  try {
    const response = await axios.get<Flag[]>(`${baseURL}flag`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching all flags `, error);
    return [];
  }
};

export const getFlags = async (installationId: number): Promise<Flag[]> => {
  try {
    const response = await axios.get<Flag[]>(`${baseURL}flag/installation/${installationId}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching flags for Installation ${installationId}:`, error);
    return [];
  }
};

export const createFlag = async (installationId: number,flag_comment:string,flagged_date:string): Promise<Flag> => {
  try {
    const response = await axios.post<Flag>(`${baseURL}flag`, {
      Installation:installationId,
      flag_comment,
      flagged_date
    });
    return response.data;
  } catch (error) {
    console.error(`Error creating flag for Installation ${installationId}:`, error);
    return null as unknown as Flag;
  }
};

export const patchFlag = async (flag_id: number,flag_comment:string,flagged_date:string): Promise<Flag> => {
  try {
    const response = await axios.patch<Flag>(`${baseURL}flag/${flag_id}`, {
      id:flag_id,
      flag_comment,
      flagged_date
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating flag  ${flag_id}:`, error);
    return null as unknown as Flag;
  }
};

export const deleteFlag = async (flag_id: number): Promise<Flag> => {
  try {
    const response = await axios.delete<Flag>(`${baseURL}flag/${flag_id}`);
    return response.data;
  } catch (error) {
    console.error(`Error deleting flag  ${flag_id}:`, error);
    return null as unknown as Flag;
  }
};

export const getDTWAlignment = async (requestData: DTWRequestBody): Promise<DTWResponseBody> => {
  try {
    const response = await axios.post<DTWResponseBody>(`${baseURL}dtw`, requestData);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('Axios error:', error.response?.data || error.message);
    } else {
      console.error('Unexpected error:', error);
    }
    throw error;
  }
};


const get_naf_data =async ()=>{
  const cacheKey = `naf`;
  const cachedData = await IndexedDBCache.get(cacheKey);

  if (cachedData) {
    return cachedData;
  }
  const reponse = await fetch('/naf.json')
  const jsonData = await reponse.json();
  IndexedDBCache.set(cacheKey, jsonData);
  return jsonData;
}

export const get_naf=async (code_naf:string)=>{
  const jsonData = await get_naf_data();
  if (code_naf in jsonData){
    return jsonData[code_naf]
  }else{
    return "null"
  }
}