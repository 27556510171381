// App.tsx
import React, { useState, useEffect } from 'react';
import { Drawer, CssBaseline } from '@mui/material';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import { fetchPipelineConfigs, fetchOutliers, getAllFlags, getDefaultUrl, setAPIBaseUrl } from './api/api';
import { PipelineConfig, Installation, ClusterObject, Outlier, flagStatusEnum } from './types/type';
import OutlierView from './components/OutlierView';
import LoadingOverlay from './components/LoadingOverlay';
import FilterBlock from './components/FilterOutliers';
import { scoreFunctions } from './utils/scores';
import { filterInfo, filterOutliers } from './utils/getValues';
import { get_oulierFilters } from './settings/oulierFilters';
import ClustersView from './components/ClustersView';

const App: React.FC = () => {
  const [pipelines, setPipelineList] = useState<PipelineConfig[]>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<PipelineConfig | null>(null);
  const [selectedInstallation, setSelectedInstallation] = useState<Installation | null>(null);
  const [selectedYearCluster, setSelectedYearCluster] = useState<ClusterObject | null>(null);
  const [selectedRatioCluster, setSelectedRatioCluster] = useState<ClusterObject | null>(null);
  const [outliers, setOutliers] = useState<Outlier[]>([]);
  const [filteredOutliers, setFilteredOutliers] = useState<Outlier[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [scoreFunc, setScoreFunc] = useState<string>("custom_ratio");
  const [filters, setFilters] = useState<filterInfo[]>([]);
  const [flagStatus, setFlagStatus] = useState<flagStatusEnum>(flagStatusEnum.all);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [baseUrl, setBaseUrl] = useState<string>(getDefaultUrl());

  const handleImportOutliersClick = async () => {
    if (!selectedPipeline || !scoreFunc) return;
    setLoading(true);
    try{
      const data = await fetchOutliers(
        selectedPipeline.pipeline_name,
        selectedYearCluster?.cluster ?? -1,
        selectedRatioCluster?.cluster ?? -1,
        selectedInstallation?.Installation ?? -1
      )
      //@ts-ignore
      const appliedScore = scoreFunctions[scoreFunc];
      appliedScore(data)
      setOutliers([...data].sort((a, b) => {
        return b.score - a.score;
      }));
      const computedFilters=get_oulierFilters(data)

      setFilters(computedFilters)      
    }
    catch (error) {
      console.error('Error fetching outliers:', error);
    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    setAPIBaseUrl(baseUrl)
    fetchPipelineConfigs().then(data => {
      if (!data || data.length === 0) {
        setDrawerOpen(true)
        return
      }
       setPipelineList(data)
      });
  }, [baseUrl]);

  useEffect(() => {
    const applyFilter = async () => {
      const filtered = filterOutliers(outliers, filters);
      const flags = await getAllFlags();
      switch (flagStatus){
        case flagStatusEnum.flagged:
          setFilteredOutliers(filtered.filter(outlier => flags.find(f => f.Installation === outlier.Installation)));
          break;
        case flagStatusEnum.notFlagged:
          setFilteredOutliers(filtered.filter(outlier => !flags.find(f => f.Installation === outlier.Installation)));
          break;
        default:
          setFilteredOutliers(filtered);
          break;
      }
    }
    applyFilter()
  }, [filters, flagStatus,outliers]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <Header toggleDrawer={toggleDrawer} />
      <LoadingOverlay loading={loading} />
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        <Sidebar
          pipelines={pipelines}
          selectedPipeline={selectedPipeline}
          setPipeline={setSelectedPipeline}
          scoreFunc={scoreFunc}
          setScoreFunc={setScoreFunc}
          baseUrl={baseUrl}
          setBaseUrl={setBaseUrl}
        />
      </Drawer>
      <div style={{ display: 'flex', flex: 1 }}>
        <main style={{ padding: '20px', flex: 1 }}>
          {selectedPipeline && (
            <div>
              <ClustersView modelName={selectedPipeline.pipeline_name}
                selectedYearCluster={selectedYearCluster}
                setSelectedYearCluster={setSelectedYearCluster}
                selectedRatioCluster={selectedRatioCluster}
                setSelectedRatioCluster={setSelectedRatioCluster}
                handleImportClick={handleImportOutliersClick} />
              {outliers.length > 0 &&
                <FilterBlock filters={filters} setFilters={setFilters} flagStatus={flagStatus} setFlagStatus={setFlagStatus} />}
            </div>
          )}
          {selectedPipeline && filteredOutliers.length > 0 &&
            <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
              <OutlierView outliers={filteredOutliers} pipeline={selectedPipeline} />
            </div>
          }
        </main>
      </div>
    </div>
  );
};

export default App;


