import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { genericDataset } from '../types/type';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

interface GenericChartProps {
  labels: string[]
  title: string,
  datasets: genericDataset[],
  minY?: number // Optional prop for minimum y-axis value
}

const GenericChart: React.FC<GenericChartProps> = ({ labels, title, datasets, minY }) => {
  const chartData = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: `${title}`,  // Use template literal here
      },
      legend: {
        display: true,
        position: 'top' as const,
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Mois',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Valeur',
        },
        min: minY !== undefined ? minY : 0, // Set the minimum value for y-axis if provided
      },
    },
  };

  return (
    <div style={{ minHeight: '400px' }}> {/* Set the minimum height here */}
      <Line data={chartData} options={options} />
    </div>
  );
};

export default GenericChart;
