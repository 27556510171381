import React, { useState, useEffect } from 'react';
import { getFlags, createFlag, patchFlag, deleteFlag } from '../api/api'; // Adjust the import path as necessary
import { Container, List, Typography } from '@mui/material';
import FlagForm from './FlagForm';
import FlagItem from './FlagItem';
import { Flag } from '../types/type';

interface FlagsListProps {
  installationId: number;
}

const FlagsList: React.FC<FlagsListProps> = ({ installationId }) => {
  const [flags, setFlags] = useState<Flag[]>([]);
  const [editingFlag, setEditingFlag] = useState<number | null>(null);
  
  const months = Array.from({ length: 12 }, (_, i) => `2023-${i + 1}`);

  const fetchFlags = async () => {
    const fetchedFlags = await getFlags(installationId);
    setFlags(fetchedFlags);
  };

  const handleCreateFlag = async (comment: string, date: string) => {
    console.log("handleCreateFlag")
    const newFlag = await createFlag(installationId, comment, date);
    if (newFlag) {
      setFlags([...flags, newFlag]);
    }
  };

  const handleUpdateFlag = async (flagId: number, comment: string, date: string) => {
    const updatedFlag = await patchFlag(flagId, comment, date);
    if (updatedFlag) {
      setFlags(flags.map(flag => (flag.id === flagId ? updatedFlag : flag)));
      setEditingFlag(null);
    }
  };

  const handleDeleteFlag = async (flagId: number) => {
    const deletedFlag = await deleteFlag(flagId);
    if (deletedFlag) {
      setFlags(flags.filter(flag => flag.id !== flagId));
    }
  };

  const startEditing = (flag: Flag) => {
    setEditingFlag(flag.id);
  };

  const cancelEditing = () => {
    setEditingFlag(null);
  };

  useEffect(() => {
    fetchFlags();
  }, [installationId]);

  return (
    <Container>
      <FlagForm onSubmit={handleCreateFlag} months={months} />
      {flags.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Flags
          </Typography>
          <List>
            {flags.map(flag => (
              <FlagItem
                key={flag.id}
                flag={flag}
                editingFlag={editingFlag}
                startEditing={startEditing}
                cancelEditing={cancelEditing}
                handleUpdateFlag={handleUpdateFlag}
                handleDeleteFlag={handleDeleteFlag}
                months={months}
              />
            ))}
          </List>
        </>
      )}
    </Container>
  );
};

export default FlagsList;
