import { ClusterObject, Flag, Installation, isPAPSMonthKey, isPAPSNumberMonthKey, Outlier, PAPSCadranMonth, PAPSMonth } from '../types/type';

export const getAllMonth = (data: PAPSMonth[]): string[] => {
  return data
    .map(item => item.month);
};


export const getMonthsForYear2023 = (data: PAPSMonth[]): string[] => {
  return data
    .filter(item => item.year === 2023)
    .map(item => item.month);
};

export const getPAPSValuesForYear = (data: PAPSMonth[], year: number, column_name: string): number[] => {
  if (!isPAPSNumberMonthKey(column_name)) {
    throw new Error(`Invalid column name: ${column_name}`);
  }
  //@ts-ignore
  return data
    .filter(item => item.year === year)
    .map(item => item[column_name]);
};

export const getPAValuesForYear = (data: PAPSMonth[], year: number): number[] => {
  return getPAPSValuesForYear(data, year, 'PAInstallationMonth')
};

export const getPSValuesForYear = (data: PAPSMonth[], year: number): number[] => {
  return getPAPSValuesForYear(data, year,'PSInstallationMonth')
};

export const getPAdivPSDividedForYear = (data: PAPSMonth[], year1: number, year2: number): number[] => {
  const year1data = data.filter(item => item.year === year1);
  const year2data = data.filter(item => item.year === year2);
  if (year1data.length === 0 || year2data.length === 0 || year2data.length !== year1data.length) return [];
  return year1data.map((item, index) => item.PA_div_PS / year2data[index].PA_div_PS);
};

export const getDiFFPADivMaxPA = (data: PAPSMonth[], yearN: number, yearNmin1: number): number[] => {
  const yearNdata = data.filter(item => item.year === yearN);
  const yearNmin1data = data.filter(item => item.year === yearNmin1);
  const maxYear2 = Math.max(...yearNdata.map(item => item.PAInstallationMonth));
  if (yearNdata.length === 0 || yearNmin1data.length === 0 || yearNmin1data.length !== yearNdata.length) return [];
  return yearNdata.map((item, index) => (yearNdata[index].PAInstallationMonth- yearNmin1data[index].PAInstallationMonth) / maxYear2);
};