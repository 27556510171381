import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

interface DropdownProps<T> {
  label: string;
  options: T[];
  value: T;
  onChange: (value: T) => void;
  getOptionLabel?: (option: T) => string;
}

const Dropdown = <T,>({ label, options, value, onChange, getOptionLabel }: DropdownProps<T>) => (
  <FormControl fullWidth>
    <InputLabel>{label}</InputLabel>
    <Select
      value={value as unknown as string}
      onChange={(e) => onChange(e.target.value as unknown as T)}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option as unknown as string}>
          {getOptionLabel ? getOptionLabel(option) as React.ReactNode : option as React.ReactNode}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default Dropdown;
