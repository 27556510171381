export enum flagStatusEnum{
  all="all",
  notFlagged="notFlagged",
  flagged="flagged",
}

export interface genericDataset {
  label: string,
  data: number[],
  borderColor: string,
  fill: boolean
}

export interface PAPSCadranMonth {
    Installation: string;
    month: string;
    PA: number;
    PS: number;
    Numero_Cadran_PA:number;
    year:number;
    month_index:number;
  }

  export interface PAPSMonth {
    Installation: string;
    month: string;
    PAInstallationMonth: number;
    PSInstallationMonth: number;
    maxPANormalized: number;
    PA_div_PS: number;
    maxPSNormalized: number;
    PA_div_PS_Normalized: number;
    PS_Normalized: number;
    year:number;
    month_index:number;
  }

  export type PAPSMonthKey = keyof PAPSMonth;

// Type guard to check if a key is a valid PAPSMonth key
export const isPAPSMonthKey = (key: string): key is PAPSMonthKey => {
  return [
    'Installation', 'month', 'PAInstallationMonth', 'PSInstallationMonth', 
    'maxPANormalized', 'PA_div_PS', 'maxPSNormalized', 'PA_div_PS_Normalized', 
    'PS_Normalized', 'year', 'month_index'
  ].includes(key);
}

export const isPAPSNumberMonthKey = (key: string): key is PAPSMonthKey=> {
  return [
    'PAInstallationMonth', 'PSInstallationMonth', 
    'maxPANormalized', 'PA_div_PS', 'maxPSNormalized', 'PA_div_PS_Normalized', 
    'PS_Normalized'
  ].includes(key);
}
  
  export interface Installation {
    Installation: number;
    Code_NAF: string;
    REGION: string;
    libelle_NAF?:string;
  }

  export interface ClusteringModel {
    model: string;
    distance_threshold: number;
  }
  
  export interface FilterModel {
    column_name: string;
    value: string | (string | number)[];
  }
  
  export interface PipelineConfig {
    base_column: string;
    clustering: ClusteringModel;
    dtw: string;
    filter: FilterModel;
    pipeline_name: string;
  }

  export interface ClusterObject {
    type: string;
    model: string;
    cluster:number
    values: number[];
    outlier_count:number;
  }

  export interface Outlier {
    id:number
    model : string
    Installation : number
    cluster : number
    MaxDistColumn: string
    SignedMaxDistance: number
    AverageDistance: number
    ratio_cluster : number
    ratio_MaxDistColumn: string
    ratio_SignedMaxDistance: number
    ratio_AverageDistance: number
    DTW_Distance: number
    DIFF_AVERAGE: number
    SIGNIFICANT_CHANGE_INDEX : number
    SIGNIFICANT_CHANGE_RATIO: number
    score:number
  }
  
  export interface Flag{
    id:number
    Installation:number
    flag_comment:string
    flagged_date:string 
  }
    
