import React, { useState } from 'react';
import { TextField, Button, Grid, Select, MenuItem, Typography, Box } from '@mui/material';

interface FlagFormProps {
  onSubmit: (comment: string, date: string) => void;
  months: string[];
}

const FlagForm: React.FC<FlagFormProps> = ({ onSubmit, months }) => {
  const [comment, setComment] = useState<string>('');
  const [date, setDate] = useState<string>('');

  const handleSubmit = () => {
    console.log("handleSubmit")
    if (date) {
      onSubmit(comment, date);
      setComment('');
      setDate('');
    }
  };

  return (
    <Box mb={2}>
      <Typography variant="h6" gutterBottom>
        Nouveau Flag
      </Typography>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={5}>
          <TextField
            label="Comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            value={date}
            onChange={(e) => setDate(e.target.value as string)}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Select Date
            </MenuItem>
            {months.map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth>
            Créer
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FlagForm;
