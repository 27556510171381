import {Outlier} from '../types/type'


function calculate_score_mulitply(outliers:Outlier[],cluster_distance_column:string){
    for (let outlier of outliers) {

        //@ts-ignore
        outlier.score = outlier[cluster_distance_column] * outlier.DTW_Distance * outlier.DIFF_AVERAGE
    }
}
    
function calculate_score_average(outliers:Outlier[],cluster_distance_column:string){
    for (let outlier of outliers) {
        //@ts-ignore
        outlier.score = (outlier[cluster_distance_column] + outlier.DTW_Distance - outlier["DIFF_AVERAGE"])/3.
    }
}

function calculate_score_custom(outliers:Outlier[],cluster_distance_column:string){
    for (let outlier of outliers) {
        //@ts-ignore
        outlier.score = (Math.abs(outlier[cluster_distance_column]) +(1. - outlier.SIGNIFICANT_CHANGE_RATIO)  * Number(outlier.SIGNIFICANT_CHANGE_INDEX>-1) - outlier.DIFF_AVERAGE)/3.
    }
}

function calculate_score_custom_ratio(outliers:Outlier[],cluster_distance_column:string){
    for (let outlier of outliers) {
        //@ts-ignore
        outlier.score = ((Math.abs(outlier.ratio_SignedMaxDistance)  + Math.abs(outlier.SignedMaxDistance))*(1. - outlier.SIGNIFICANT_CHANGE_RATIO)  * Number(outlier.SIGNIFICANT_CHANGE_INDEX>-1))
    }
}

export const scoreFunctions={
    "multiply": calculate_score_mulitply,
    "average": calculate_score_average,
    "custom": calculate_score_custom,
    "custom_ratio": calculate_score_custom_ratio
}
