import React, { useState } from 'react';
import { Box, Slider, Typography, Grid, Button, Collapse, Checkbox, FormControlLabel, RadioGroup, FormLabel, FormControl, Radio } from '@mui/material';
import { filterInfo } from '../utils/getValues';
import { flagStatusEnum } from '../types/type';

interface FilterBlockProps {
  filters: filterInfo[];
  setFilters: (filters: filterInfo[]) => void;
  flagStatus: flagStatusEnum;
  setFlagStatus: (flagStatus: flagStatusEnum) => void;
}

const FilterBlock: React.FC<FilterBlockProps> = ({ filters, setFilters, flagStatus: onlyFlagged, setFlagStatus: setOnlyFlagged }) => {
  const [filterBlockVisible, setFilterBlockVisible] = useState<boolean>(true);

  const handleSliderChange = (index: number) => (event: Event, newValue: number | number[]) => {
    const newFilters = [...filters];
    newFilters[index].value = newValue as number;
    setFilters(newFilters);
  };

  const handleFlagStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnlyFlagged(event.target.value as unknown as flagStatusEnum);
  };

  return (
    <Box component="section" sx={{ p: 2, border: '1px dashed grey', marginTop: '10px' }}>
      <Button variant="outlined" color="secondary" onClick={() => setFilterBlockVisible(!filterBlockVisible)}>
        {filterBlockVisible ? 'Cacher Filtres' : 'Afficher Filtres'}
      </Button>
      <Collapse in={filterBlockVisible}>
        <Grid container spacing={3}>
          {filters.map((filter, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box mb={2}>
                <Typography gutterBottom>
                  {filter.title}
                </Typography>
                <Slider
                  value={filter.value}
                  onChange={handleSliderChange(index)}
                  aria-labelledby={`filter-slider-${index}`}
                  min={filter.min}
                  max={filter.max}
                  step={filter.step}
                  valueLabelDisplay="auto"
                />
              </Box>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Flag</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={handleFlagStatusChange}
              defaultValue={flagStatusEnum.all}
            >
              <FormControlLabel value={flagStatusEnum.all} control={<Radio />} label="Tous" />
              <FormControlLabel value={flagStatusEnum.flagged} control={<Radio />} label="Flaguée" />
              <FormControlLabel value={flagStatusEnum.notFlagged} control={<Radio />} label="Pas flaguée" />
            </RadioGroup>
          </FormControl>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

export default FilterBlock;
